<template>
  <div>
    <v-dialog v-model="localDialog" max-width="700px" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">Modifier la participation</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col v-if="editedParticipation.id === -1">
                <v-autocomplete
                  v-model="editedParticipation.user_id"
                  label="Nom"
                  :items="getActiveMembers"
                  item-text="fullname"
                  item-value="id"
                  filled
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="editedParticipation.grimpette"
                  label="Grimpette"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="editedParticipation.descente"
                  label="Descente"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="editedParticipation.chute"
                  label="Chute"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="editedParticipation.extra"
                  label="Extra"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="editedParticipation.malus"
                  label="Malus"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeParticipation"
            >Fermer</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  props: {
    participation: {
      type: Object,
      required: false
    },
    action: {
      type: String,
      required: true
    },
    dialogParticipation: {
      type: Boolean,
      required: true
    },
    variante: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      editedParticipation: {},
      localDialog: false
      //dialogVariante: false
    }
  },
  computed: {
    ...mapState(['membres']),
    ...mapActions(['fetchMembres']),
    ...mapGetters(['getActiveMembers'])
  },
  created() {
    this.fetchMembres
  },
  watch: {
    dialogParticipation() {
      if (this.dialogParticipation == true) {
        this.editedParticipation = this.participation
        this.localDialog = this.dialogParticipation
      }
    }
  },
  methods: {
    closeParticipation() {
      if (this.editedParticipation.id === -1) {
        //nouvelle participation
        this.$store
          .dispatch('addParticipation', this.editedParticipation)
          .then(() => {
            this.localDialog = false
            // this.dialogParticipation = false
            this.$emit('closeParticipation')
          })
      } else {
        //modif de participation
        this.$store
          .dispatch('updateParticipation', this.editedParticipation)
          .then(() => {
            this.localDialog = false
            // this.dialogParticipation = false
            this.$emit('closeParticipation')
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
