<template>
  <v-container>
    <v-row justify-center>
      <v-col cols="12">
        <v-card :loading="loading">
          <v-card-title v-if="id">
            <h1>Modification membre</h1>
          </v-card-title>
          <v-card-title v-else>
            <h1>Nouveau membre</h1>
          </v-card-title>
          <v-card-text>
            <v-form ref="newMembre" @submit.prevent="createMembre">
              <v-text-field name="Prénom" label="Prénom" id="prenom" v-model="newMembre.prenom"></v-text-field>

              <v-text-field name="Nom" label="Nom" id="nom" v-model="newMembre.name"></v-text-field>
              <v-text-field name="Email" label="email" id="email" v-model="newMembre.email"></v-text-field>
              <v-text-field
                name="Pseudo"
                label="Pseudo forum"
                id="pseudo"
                v-model="newMembre.pseudo"
              ></v-text-field>

              <v-select
                :items="status"
                label="Statut Membre"
                v-model="newMembre.user_status_id"
                item-text="name"
                item-value="id"
              ></v-select>
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="newMembre.date_inscription"
                    label="Date d'arrivée du membre"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="newMembre.date_inscription" @input="menu = false"></v-date-picker>
              </v-menu>
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="newMembre.date_sortie"
                    label="Date de sortie du membre"
                    readonly
                    clearable
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="newMembre.date_sortie" @input="menu2 = false"></v-date-picker>
              </v-menu>
              <v-btn class="mr-4" type="submit" color="primary">Soumettre</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
//import { mapGetters } from 'vuex'
import store from '@/store'
import { mapActions } from 'vuex'
import EventService from '@/services/EventService.js'
import router from '@/router'
export default {
  props: {
    id: {
      required: false
    }
  },
  data() {
    return {
      newMembre: {},
      menu: false,
      menu2: false,
      status: [],
      loading: true
    }
  },
  methods: {
    initEmptyMembre() {
      if (this.id == null) {
        return {
          prenom: '',
          name: '',
          email: '',
          pseudo: '',
          user_status_id: '',
          date_inscription: '',
          date_sortie: '',
          password: 'test'
        }
      } else {
        return store.getters.getMembreById(Number(this.id))
      }
    },
    createMembre() {
      if (this.id == null) {
        this.$store.dispatch('createMembre', this.newMembre).then(() => {
          this.newMembre = this.initEmptyMembre()
          router.push({ name: 'membres' })
        })
      } else {
        this.$store.dispatch('updateMembre', this.newMembre).then(() => {
          this.newMembre = this.initEmptyMembre()
          router.push({ name: 'membre-show', params: { id: this.id } })
        })
      }
    }
  },
  computed: {
    ...mapActions(['fetchMembres'])
  },
  created() {
    if (!this.$laravel.hasPermission('editer membres')) {
      // redirect
      router.push({ name: 'membres' })
    }

    this.fetchMembres
      .then(() => {
        this.loading = false
      })
      .then(() => {
        EventService.fetchStatusMembres().then(response => {
          this.status = response.data
        })
      })
      .then(() => {
        this.newMembre = this.initEmptyMembre()
      })
  }
}
</script>

<style scoped>
</style>