<template>
  <div>
    <v-card>
      <v-card-title>Sorties de la saison : {{ getNameSaisonCourante }}</v-card-title>
      <v-card-text>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="sorties"
          @click:row="selectRow"
          class="pointer"
          :mobile-breakpoint="0"
        ></v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import EventService from '@/services/EventService.js'
import { mapGetters } from 'vuex'
import router from '@/router'
export default {
  props: {
    membreId: {
      required: true
    }
  },
  data: () => ({
    sorties: [],
    loading: true,
    headers: [
      { text: 'Date', value: 'date' },
      { text: 'Nom', value: 'nom' },
      { text: 'Lieu', value: 'lieu' },
      { text: 'Variante', value: 'nomVariante' },
      { text: 'Distance', value: 'km' },
      { text: 'Déniv', value: 'deniv' },
      { text: 'Pt Rando', value: 'pointRando' },
      { text: 'Mauvais temps', value: 'pointMauvaisTemps' },
      { text: 'Pt Km', value: 'point_km' },
      { text: 'Grimpette', value: 'grimpette' },
      { text: 'Descente', value: 'descente' },
      { text: 'Chute', value: 'chute' },
      { text: 'Extra', value: 'extra' },
      { text: 'Malus', value: 'malus' }
    ]
  }),
  computed: {
    ...mapGetters([
      'gedIdSaisonCourante',
      'getIndexSaisonCourante',
      'getNameSaisonCourante'
    ])
  },
  mounted() {
    EventService.fetchMembreSorties(
      this.membreId,
      this.gedIdSaisonCourante
    ).then(reponse => {
      this.loading = false
      this.sorties = reponse.data
    })
  },
  methods: {
    selectRow(sortie) {
      router.push({ name: 'sortie-show', params: { id: sortie.sortie_id } })
    }
  },
  watch: {
    getIndexSaisonCourante() {
      this.loadingSorties = true
      this.sorties = []
      EventService.fetchMembreSorties(
        this.membreId,
        this.gedIdSaisonCourante
      ).then(response => {
        this.loading = false
        this.sorties = response.data
      })
    }
  }
}
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
