import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,[_c(VCardTitle,[_vm._v(" Classement "+_vm._s(_vm.getNameSaisonCourante)+" "),_c(VSpacer),_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Recherche","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VDataTable,{staticClass:"pointer",attrs:{"headers":_vm.headers,"items":_vm.classement,"items-per-page":10,"search":_vm.search,"mobile-breakpoint":0},on:{"click:row":_vm.selectRow}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }