<template>
  <v-container>
    <v-skeleton-loader :loading="loading" type="card">
      <miniCardMembre :membre="infosMembre"></miniCardMembre>
    </v-skeleton-loader>
    <statsMembre :membreId="id"></statsMembre>
    <sortiesMembre :membreId="id"></sortiesMembre>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import statsMembre from '@/components/statsMembre.vue'
import sortiesMembre from '@/components/sortiesMembre.vue'
import miniCardMembre from '@/components/miniCardMembre.vue'
export default {
  props: ['id'],
  data: () => ({
    loading: true,
    infosMembre: {}
  }),
  components: {
    statsMembre,
    sortiesMembre,
    miniCardMembre
  },
  computed: {
    ...mapGetters(['getMembreById']),
    ...mapActions(['fetchMembres'])
  },
  mounted() {
    this.fetchMembres.then(() => {
      this.loading = false
      this.infosMembre = this.getMembreById(Number(this.id))
    })
  }
}
</script>

<style scoped></style>
