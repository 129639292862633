import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,[_c(VCol,[_c(VRow,[_c(VCol,[_c(VSkeletonLoader,{attrs:{"type":"card","loading":_vm.loadingStats}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-center"},[_vm._v("Sorties cette saison")]),_c(VCardText,{staticClass:"display-2 text-center"},[_vm._v(_vm._s(_vm.getNbsorties))])],1)],1)],1),_c(VCol,[_c(VSkeletonLoader,{attrs:{"type":"card","loading":_vm.loadingStats}},[_c(VCard,[_c(VCardTitle,[_vm._v("Kilomètres parcourus")]),_c(VCardText,{staticClass:"display-2 text-center"},[_vm._v(_vm._s(_vm.getKm))])],1)],1)],1),_c(VCol,[_c(VSkeletonLoader,{attrs:{"type":"card","loading":_vm.loadingStats}},[_c(VCard,[_c(VCardTitle,[_vm._v("Dénivelé grimpé")]),_c(VCardText,{staticClass:"display-2 text-center"},[_vm._v(_vm._s(_vm.getDeniv))])],1)],1)],1),_c(VCol,[_c(VSkeletonLoader,{attrs:{"type":"card","loading":_vm.loadingStats}},[_c(VCard,[_c(VCardTitle,[_vm._v("Fréquentation moyenne")]),_c(VCardText,{staticClass:"display-2 text-center"},[_vm._v(_vm._s(_vm.getPart))])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }