import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VCard,{staticClass:"mx-auto mt-5"},[_c(VCardTitle,{attrs:{"primary-title":""}},[_vm._v("Photos de la sortie")]),_c(VCardText,[(_vm.sortie.photos.length > 0)?_c('div',[_c(VCarousel,{attrs:{"cycle":"","height":"500","hide-delimiter-background":"","show-arrows-on-hover":""}},_vm._l((_vm.sortie.photos),function(photo){return _c(VCarouselItem,{key:photo.id},[_c('div',{staticClass:"container"},[_c('a',{attrs:{"href":_vm.createUrl(photo.filename),"target":"_blank"}},[_c(VImg,{attrs:{"max-height":"500","src":_vm.createUrl(photo.thumbnail)}})],1),_c('div',{staticClass:"bottom-left"},[_vm._v("Envoyée par "+_vm._s(photo.user_name))]),_c('div',{directives:[{name:"permission",rawName:"v-permission",value:('editer sorties'),expression:"'editer sorties'"}],staticClass:"upper-right"},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.deletePhoto(photo.id)}}},[_vm._v("Supprimer")])])])])}),1),_c(VContainer,[_c(VLayout,{attrs:{"align-center":""}},[_c(VFlex)],1)],1)],1):_vm._e(),[_c('div',{on:{"drop":function($event){$event.preventDefault();return _vm.addDropFile.apply(null, arguments)},"dragover":function($event){$event.preventDefault();}}},[_c(VFileInput,{ref:"files",attrs:{"accept":".jpg","label":"Vous pouvez envoyer des photos au format .jpg (selectionnez plusieurs photos en maintenant ctrl enfoncé)","multiple":"","chips":"","error-messages":_vm.error,"counter":"","prepend-icon":"mdi-camera"},model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}})],1),_c(VBtn,{staticClass:"mr-4",attrs:{"color":"primary","loading":_vm.loading,"disabled":_vm.disabled},on:{"click":_vm.envoiPhotos}},[_vm._v("Soumettre")]),(_vm.message)?_c(VAlert,{staticClass:"float-right",attrs:{"type":"info"}},[_vm._v(" "+_vm._s(_vm.message)+" ")]):_vm._e()]],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }