<template>
  <div>
    <iframe
      v-if="variantetoedit.visuGpxId != '0'"
      :src="'https://www.visugpx.com/' + variantetoedit.visuGpxId + '?iframe'"
      allowfullscreen
      style="border: 0;width: 100%; height: 540px;"
      scrolling="no"
    ></iframe>
    <v-btn
      v-if="variantetoedit.visuGpxId != '0'"
      class="mr-4"
      @click="deleteGpx"
      color="primary"
      v-permission="'editer sorties'"
    >Supprimer</v-btn>
    <template v-else>
      <div v-cloak @drop.prevent="addDropFile" @dragover.prevent>
        <v-file-input
          ref="file"
          accept=".gpx"
          label="Tout membre peut envoyer le GPX de la variante à laquelle il a participé"
          :rules="rules"
          v-model="file"
          :error-messages="error"
        ></v-file-input>
      </div>
      <v-btn class="mr-4" @click="envoiGpx" color="primary">Soumettre</v-btn>
    </template>
  </div>
</template>


<script>
import EventService from '@/services/EventService.js'
export default {
  props: {
    variante: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      file: null,
      error: '',
      rules: [
        value =>
          !value || value.size < 8000000 || 'Le gpx doit faire moins de 8 MB!'
      ],
      variantetoedit: {...this.variante}
    }
  },
  methods: {
    envoiGpx() {
      if (this.file) {
        let formData = new FormData()
        formData.append('gpx', this.file)
        EventService.envoiGpx(this.variantetoedit.id, formData)
          .then(response => {
            this.variantetoedit.visuGpxId = response.data.gpx_id
          })
          .catch(error => {
            this.error = error.response.data
          })
      }
    },
    deleteGpx() {
      EventService.deleteGpx(this.variantetoedit.id).then(() => {
        this.variantetoedit.visuGpxId = '0'
      })
    },
    //https://github.com/vuetifyjs/vuetify/issues/7836
    addDropFile(e) {
      this.file = e.dataTransfer.files[0]
    }
  }
}
</script>

<style scoped></style>
