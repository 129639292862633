import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,[_c(VCardTitle,[_c(VAlert,{attrs:{"border":"right","colored-border":"","type":"error","elevation":"2"}},[_vm._v(" ADMINISTRATION SAISONS !!! SOYEZ PRUDENTS ")]),_c(VSpacer),_c(VAlert,{attrs:{"border":"top","colored-border":"","type":"info","elevation":"2"}},[_vm._v(" Ajouter une saison -> le + à droite"),_c('br'),_vm._v(" Modifier l'intitulé d'une saison -> Cliquer sur la saison ")]),_c(VSpacer),_c(VBtn,{directives:[{name:"permission",rawName:"v-permission",value:('editer membres'),expression:"'editer membres'"}],attrs:{"absolute":"","dark":"","fab":"","right":"","color":"pink","to":"/admin/saison/create"}},[_c(VIcon,[_vm._v("mdi-plus")])],1)],1),_c(VDataTable,{attrs:{"loading":_vm.loadingMembres,"headers":_vm.headers,"items":_vm.saisons,"items-per-page":20},on:{"click:row":_vm.selectRow}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }