import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('editParticipation',{attrs:{"participation":_vm.editedParticipation,"action":"Ajout Participation","dialogParticipation":_vm.dialogParticipation},on:{"closeParticipation":_vm.closeParticipation}}),_c(VCol,[_c(VMenu,{directives:[{name:"permission",rawName:"v-permission",value:('editer sorties'),expression:"'editer sorties'"}],scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VLayout,{attrs:{"justify-end":""}},[_c(VBtn,_vm._g({attrs:{"icon":""}},on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)],1)]}}])},[_c(VList,[_c('editVarianteDialog',{attrs:{"variante":_vm.editedVariante,"action":"Editer Variante"}}),_c(VListItem,[_c(VListItemTitle,{on:{"click":function($event){return _vm.deleteVariante(_vm.editedVariante)}}},[_c(VBtn,[_c(VIcon,{staticClass:"mr-2",attrs:{"left":""}},[_vm._v("mdi-delete")]),_vm._v("Supprimer ")],1)],1)],1),_c(VListItem,[_c(VListItemTitle,{on:{"click":function($event){return _vm.addParticipation()}}},[_c(VBtn,[_c(VIcon,{staticClass:"mr-2",attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v("Ajouter membre ")],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }