<template>
  <div>
    <v-list-item link>
      <v-list-item-title>
        <v-dialog v-model="dialogVariante" max-width="500px" persistent>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" @click="editVariante()">
              <v-icon left class="mr-2">mdi-pencil</v-icon>
              {{ action }}
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ action }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-select
                      v-model="localVariante.type_variante_id"
                      label="Type de variante"
                      :items="variantes"
                      item-text="nom"
                      item-value="id"
                    ></v-select>
                    <v-text-field
                      v-model="localVariante.km"
                      label="Distance"
                    ></v-text-field>
                    <v-text-field
                      v-model="localVariante.deniv"
                      label="Dénivelé"
                    ></v-text-field>
                    <v-text-field
                      v-model="localVariante.vitesse"
                      label="Vitesse"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="closeVariante(localVariante)"
                >Enregistrer</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-list-item-title>
    </v-list-item>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  props: {
    variante: {
      type: Object
      // required: true
    },
    sortie: {
      type: Object
    },
    action: { type: String, required: true }
  },
  computed: {
    ...mapState(['variantes']),
    ...mapActions(['updateVariante', 'fetchVariantes'])
  },
  data() {
    return {
      localVariante: {},
      dialogVariante: false
    }
  },
  mounted: function() {
    this.fetchVariantes
    if (this.variante == null) {
      this.localVariante = {
        id: -1,
        sortie_id: this.sortie.id,
        type_variante_id: 0,
        type_variante_nom: '',
        km: 0,
        deniv: 0,
        vitesse: 0,
        visuGpxId: '0',
        participants: []
      }
    } else {
      this.localVariante = this.variante
    }
  },
  methods: {
    closeVariante(variante) {
      variante.type_variante_nom = this.variantes.find(
        obj => obj.id == variante.type_variante_id
      ).nom
      if (variante.id === -1) {
        //new variante
        //this.localVariante.sortie_id = sortie.id

        this.$store.dispatch('addVariante', variante).then(() => {
          //   this.sortie.variantes.push(
          //     response.data.data.variantes.find(
          //       varianteToFind =>
          //         varianteToFind.type_variante_id === variante.type_variante_id
          //     )
          //   )

          this.dialogVariante = false

          // Reset de la variante editée si besoin de création variante
          //   this.localVariante.id = -1
          //   this.localVariante.sortie_id = null
          //   this.localVariante.type_variante_id = 0
          //   this.localVariante.type_variante_nom = ''
          //   this.localVariante.km = 0
          //   this.localVariante.deniv = 0
          //   this.localVariante.vitesse = 0
          //   this.localVariante.participants = []
        })
      } else {
        //modif variante
        this.$store.dispatch('updateVariante', variante).then(() => {
          this.dialogVariante = false
          // Reset de la variante editée si besoin de création variante
          //   this.localVariante.id = -1
          //   this.localVariante.sortie_id = null
          //   this.localVariante.type_variante_id = 0
          //   this.localVariante.type_variante_nom = ''
          //   this.localVariante.km = 0
          //   this.localVariante.deniv = 0
          //   this.localVariante.vitesse = 0
          //   this.localVariante.participants = []
        })
      }
    },
    ajoutVariante() {
      this.dialogVariante = true
    },
    editVariante() {
      this.dialogVariante = true
    }
  }
}
</script>

<style lang="scss" scoped></style>
