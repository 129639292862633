<template>
  <v-row justify="center">
    <v-col cols="4" md="4">
      <v-card>
        <v-card-title>
          Statistiques globales {{ getNameSaisonCourante }}
        </v-card-title>
        <v-skeleton-loader
          class="mx-auto"
          max-width="300"
          type="table"
          :loading="loadingStats"
        >
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Nom
                  </th>
                  <th class="text-left">
                    Stat
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, key) in stats" :key="item.key">
                  <td>{{ key }}</td>
                  <td>{{ item }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-skeleton-loader>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// import router from '@/router'
//import { mapGetters } from 'vuex'
import EventService from '@/services/EventService.js'

export default {
  name: 'tableauStats',

  data: () => ({
    stats: [],
    loadingStats: true
  }),
  created: function() {
    this.$store.dispatch('fetchSaisons').then(() => {
      EventService.getStats(this.$store.getters.gedIdSaisonCourante).then(
        response => {
          this.stats = response.data
          this.loadingStats = false
        }
      )
    })
  },
  computed: {
    //...mapGetters(['getNameSaisonCourante'])
    getNameSaisonCourante() {
      return this.$store.getters.getNameSaisonCourante
    }
  }
}
</script>

<style scoped>
</style>
