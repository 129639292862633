import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"12","md":"8"}},[_c(VSheet,{attrs:{"cols":"12"}},[_c(VSkeletonLoader,{attrs:{"type":"heading","loading":_vm.loadingSorties}},[_c('h1',[_vm._v(" Liste des sorties pour la saison "+_vm._s(_vm.getNameSaisonCourante)+" ")])]),_c(VBtn,{directives:[{name:"permission",rawName:"v-permission",value:('editer sorties'),expression:"'editer sorties'"}],attrs:{"absolute":"","dark":"","fab":"","right":"","color":"pink","to":"/sortie/create"}},[_c(VIcon,[_vm._v("mdi-plus")])],1)],1)],1)],1),_vm._l((_vm.sorties),function(sortie){return _c(VRow,{key:`${sortie.id}`,attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"12","md":"8"}},[_c(VSkeletonLoader,{attrs:{"type":"card","loading":_vm.loadingSorties}},[_c('miniCardSortie',{attrs:{"sortie":sortie}})],1)],1)],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }