<template>
  <v-container>
     <v-row>
          <v-col cols="12">
        <v-card>
          <v-card-title>
            
                <v-alert
      border="right"
      colored-border
      type="error"
      elevation="2"
    >
      ADMINISTRATION SAISONS !!! SOYEZ PRUDENTS
    </v-alert>
    <v-spacer></v-spacer>
    <v-alert
      border="top"
      colored-border
      type="info"
      elevation="2"
    >
      Ajouter une saison -> le + à droite<br/>
      Modifier l'intitulé d'une saison -> Cliquer sur la saison

    </v-alert>

            <v-spacer></v-spacer>
            <v-btn
              v-permission="'editer membres'"
              absolute
              dark
              fab
              right
              color="pink"
              to="/admin/saison/create"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-card-title>

          <v-data-table
             :loading="loadingMembres"
             :headers="headers"
            :items="saisons"
            :items-per-page="20"
            @click:row="selectRow"
          ></v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import router from '@/router'
import { mapState, mapActions } from 'vuex'
export default {
  data: () => ({
    headers: [
      {
        text: 'ID',
        value: 'id'
      },
      {
        text: 'Nom',
        value: 'nom'
      }
    ],
    loadingMembres: true,
  }),
  computed: {
    ...mapState(['saisons']),
    ...mapActions(['fetchSaisons'])
  },
    mounted() {
    this.fetchSaisons.then(() => {
      this.loadingMembres = false
    })
    },
  methods: {
    selectRow(saison) {
      router.push({ name: 'saison-edit', params: { id: saison.id } })
    }
  }
}

</script>