import axios from 'axios'
import store from '@/store/index.js'
//import NProgress from 'nprogress'

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json'
  },
  timeout: 10000
})

// Si le cookie a expiré, l'appli pense que le user est connecté.
// Lors de la premiere 401, le déconnecter du point de vue de l'appli
apiClient.interceptors.response.use(
  response => response,
  error => {
    if (
      error.response.status === 401 &&
      error.response.data.message != 'The link is not valid'
    ) {
      store.dispatch('logout')
    }
    return Promise.reject(error)
  }
)

export default {
  getVariantes() {
    return apiClient.get('/api/typeVariantes')
  },
  getSorties(saisonId) {
    return apiClient.get('/api/saisons/' + saisonId + '/sorties')
  },
  getSortie(sortie) {
    return apiClient.get('/api/sorties/' + sortie)
  },
  getClassement(saisonId) {
    return apiClient.get('/api/saisons/' + saisonId + '/classement')
  },
  getTimeline() {
    return apiClient.get('/api/timeline')
  },
  getStats(saisonId) {
    return apiClient.get('/api/saisons/' + saisonId + '/stats')
  },
  postSortie(sortie) {
    return apiClient.post('/api/sorties', sortie)
  },
  updateSortie(sortie) {
    return apiClient.put('/api/sorties/' + sortie.id, sortie)
  },
  deleteSortie(sortie) {
    return apiClient.delete('/api/sorties/' + sortie.id)
  },
  updateVariante(variante) {
    return apiClient.put('/api/variantes/' + variante.id, variante)
  },
  addVariante(variante) {
    return apiClient.post('/api/variantes', variante)
  },
  deleteVariante(variante) {
    return apiClient.delete('/api/variantes/' + variante.id)
  },
  updateParticipation(participation) {
    return apiClient.put(
      '/api/participations/' + participation.id,
      participation
    )
  },
  addParticipation(participation) {
    return apiClient.post('/api/participations', participation)
  },
  deleteParticipation(participation) {
    return apiClient.delete('/api/participations/' + participation.id)
  },
  getTypeSorties() {
    return apiClient.get('/api/typeSorties')
  },
  getSaisons() {
    return apiClient.get('/api/saisons')
  },
  updateSaison(saison) {
    return apiClient.put('/api/saisons/' + saison.id, saison)
  },
  addSaison(saison) {
    return apiClient.post('/api/saisons', saison)
  },
  getMembres() {
    return apiClient.get('/api/membres')
  },
  postMembre(membre) {
    return apiClient.post('/api/membres', membre)
  },
  updateMembre(membre) {
    return apiClient.put('/api/membres/' + membre.id, membre)
  },
  fetchMembreStats(membreId) {
    return apiClient.get('/api/membres/' + membreId + '/stats')
  },
  fetchMembreSorties(membreId, saisonId) {
    return apiClient.get(
      '/api/saisons/' + saisonId + '/membres/' + membreId + '/sorties'
    )
  },
  fetchStatusMembres() {
    return apiClient.get('/api/statusMembres')
  },
  envoiGpx(varianteId, gpx) {
    return apiClient.post('/api/variantes/' + varianteId + '/uploadGpx', gpx)
  },
  deleteGpx(varianteId) {
    return apiClient.delete('/api/variantes/' + varianteId + '/uploadGpx')
  },
  envoiPhoto(sortieId, photos) {
    return apiClient.post('/api/sorties/' + sortieId + '/photos', photos)
  },
  deletePhoto(photo) {
    return apiClient.delete('/api/photos/' + photo)
  },
  login(userData) {
    return apiClient.get('/sanctum/csrf-cookie').then(() => {
      return apiClient.post('/api/login', userData)
    })
  },
  passwordlessLogin(email) {
    return apiClient.post('/api/sendSecuredLoginEmail', email)
  },
  loginFromEmail(url, user_type, signature) {
    return apiClient.get('/sanctum/csrf-cookie').then(() => {
      return apiClient.get(
        '/magic-login' +
          url +
          '&user_type=' +
          user_type +
          '&signature=' +
          signature
      )
    })
  },
  logout() {
    return apiClient.get('/api/logout')
  }
  // getEvent(id) {
  //   return apiClient.get('/events/' + id)
  // },
  // postEvent(event) {
  //   return apiClient.post('/events', event)
  // }
}
