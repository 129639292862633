<template>
  <v-container>
    <v-row>
    <v-col>
      <miniStatsCards></miniStatsCards>
     </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-skeleton-loader type="table" :loading="loadingSaisons">
          <tableauClassement :classement="classement" />
        </v-skeleton-loader>
      </v-col>
      <v-col>
        <v-skeleton-loader type="article" :loading="loadingTimeline">
          <timeline :timeline="timeline"></timeline>
        </v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row></v-row>
  </v-container>
</template>

<script>
import tableauClassement from '@/components/tableauClassement.vue'
import timeline from '@/components/timeline.vue'
import miniStatsCards from '@/components/miniStatsCards.vue'
//import { mapState } from 'vuex'
import EventService from '@/services/EventService.js'
import { mapGetters } from 'vuex'
export default {
  components: {
    tableauClassement,
    timeline,
    miniStatsCards
  },
  data: () => ({
    loadingSaisons: true,
    loadingTimeline: true,
    classement: [],
    timeline: [],
 //   ministats: []
  }),
  // computed: mapState({
  //   classement: 'classement'
  // }),
  computed: {
    ...mapGetters(['getIndexSaisonCourante'])
  },
  created: function() {
    this.$store.dispatch('fetchSaisons').then(() => {
      EventService.getClassement(this.$store.getters.gedIdSaisonCourante)
        .then(response => {
          this.classement = response.data
          this.loadingSaisons = false
        })
        .then(() => {
          EventService.getTimeline().then(response => {
            this.timeline = response.data.data
            this.loadingTimeline = false
          })
        })
    })
  },
  watch: {
    getIndexSaisonCourante() {
      this.loadingSorties = true
      EventService.getClassement(this.$store.getters.gedIdSaisonCourante).then(
        response => {
          this.classement = response.data
          this.loadingSaisons = false
        }
      )
    }
  }
}
</script>
