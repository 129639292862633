import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"4","md":"4"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" Statistiques globales "+_vm._s(_vm.getNameSaisonCourante)+" ")]),_c(VSkeletonLoader,{staticClass:"mx-auto",attrs:{"max-width":"300","type":"table","loading":_vm.loadingStats}},[_c(VSimpleTable,{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" Nom ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Stat ")])])]),_c('tbody',_vm._l((_vm.stats),function(item,key){return _c('tr',{key:item.key},[_c('td',[_vm._v(_vm._s(key))]),_c('td',[_vm._v(_vm._s(item))])])}),0)]},proxy:true}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }