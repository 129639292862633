<template>
  <div>
    <v-timeline align-top :dense="true">
      <v-timeline-item
        v-for="(item, i) in timeline"
        :key="i"
        :color="item.color"
        :icon="item.icon"
        fill-dot
      >
        <v-card :color="item.color" dark>
          <v-card-title class="title pt-1 pb-3">
            <h3 class="pt-3 body-1 mb-0 ml-5">{{ item.date }}</h3>

            <router-link
              class="pt-3 body-1 mb-0 ml-1"
              :to="{ name: 'sortie-show', params: { id: item.timelineable_id }}"
            >
              <div class="text-white">{{ item.action }}</div>
            </router-link>
          </v-card-title>
        </v-card>
      </v-timeline-item>
    </v-timeline>
  </div>
</template>

<script>
export default {
  name: 'EventTimeline',
  props: {
    timeline: {
      required: true
    }
  }
}
</script>

<style scoped>
a {
  color: white !important;
}
</style>