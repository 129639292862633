<template>
  <div>
    <v-card
      class="mx-auto mt-5"
      :loading="loading"
      link
      hover
      :to="{ name: 'sortie-show', params: { id: sortie.id } }"
    >
      <v-card-title>{{ sortie.nom }}</v-card-title>
      <v-card-subtitle>
        <v-row>
          <v-col cols="6" md="3">
            <v-icon>mdi-map-marker</v-icon>
            {{ sortie.lieu }}
          </v-col>
          <v-col cols="6" md="3">
            <v-icon>mdi-calendar</v-icon>
            {{ sortie.date }}
          </v-col>
          <v-col cols="6" md="4">
            <v-icon>mdi-routes</v-icon>
            {{ sortie.type_sortie_nom }}
          </v-col>
          <v-col cols="1" md="1" v-if="sortie.pointRando">
            <v-icon color="green" title="Point rando accordé"
              >mdi-hiking</v-icon
            >
          </v-col>
          <v-col cols="1" md="1" v-if="sortie.pointMauvaisTemps">
            <v-icon color="green" title="Point mauvais temps accordé"
              >mdi-weather-pouring</v-icon
            >
          </v-col>
        </v-row>
      </v-card-subtitle>
      <v-card-text>
        <p>Commentaire : {{ sortie.commentaire }}</p>

        <v-sheet v-for="variante in sortie.variantes" :key="variante.id">
          <v-divider></v-divider>
          <v-row>
            <v-col>
              <v-icon>mdi-chart-sankey-variant</v-icon>
              Version {{ variante.type_variante_nom }}
            </v-col>
            <v-col>
              <v-icon>mdi-account</v-icon>
              {{ variante.nb_participants }}
            </v-col>
            <v-col>
              <v-icon>mdi-map-marker-distance</v-icon>
              {{ variante.km }} km
            </v-col>
            <v-col>
              <v-icon>mdi-image-filter-hdr</v-icon>
              {{ variante.deniv }} m
            </v-col>
            <v-col>
              <v-icon>mdi-speedometer</v-icon>
              {{ variante.vitesse }} km/h
            </v-col>
          </v-row>
        </v-sheet>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    sortie: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false
    }
  }
}
</script>

<style lang="scss" scoped></style>
