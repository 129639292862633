<template>
  <div>
    <v-card width="400" class="mx-auto mt-5">
      <v-card-title>
        <h1 class="display-1">Connexion</h1>
      </v-card-title>
      <v-card-text v-if="error">
        <v-alert type="error">{{ error }}</v-alert>
      </v-card-text>
      <v-card-text v-else>Connexion en cours</v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'LoginEmail',

  data() {
    return {
      error: null
    }
  },
  created() {
    const path = this.$route.query.url.split('magic-login')

    this.$store
      .dispatch('loginFromEmail', {
        url: path[1].toString().replace(/\\/g, ''),
        user_type: this.$route.query.user_type,
        signature: this.$route.query.signature
      })
      .then(() => {
        this.$router.push({ name: 'Classement' })
      })
      .catch(err => {
        if (err.response.data.message == null) {
          this.error = 'Le lien est expiré ou invalide'
        } else {
          this.error = err.response.data.message
        }
      })
  }
}
</script>

<style lang="scss" scoped></style>
