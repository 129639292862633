<template>
  <div>
    <v-card>
      <v-card-title>
        <h1>Stats de {{ membre.prenom }} {{ membre.name }}</h1>
      </v-card-title>
    </v-card>
    <p v-permission="'editer membres'">
      <router-link :to="{ name: 'membre-edit' }">Editer membre</router-link>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    membre: {
      required: true
    }
  }
}
</script>

<style lang="scss" scoped></style>
