<template>
  <div>
    <editParticipation
      :participation="editedParticipation"
      action="Ajout Participation"
      :dialogParticipation="dialogParticipation"
      @closeParticipation="closeParticipation"
    ></editParticipation>
    <v-col>
      <v-menu v-permission="'editer sorties'">
        <template v-slot:activator="{ on }">
          <v-layout justify-end>
            <v-btn icon v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </v-layout>
        </template>

        <v-list>
          <editVarianteDialog
            :variante="editedVariante"
            action="Editer Variante"
          ></editVarianteDialog>
          <v-list-item>
            <v-list-item-title @click="deleteVariante(editedVariante)">
              <v-btn>
                <v-icon left class="mr-2">mdi-delete</v-icon>Supprimer
              </v-btn>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title @click="addParticipation()">
              <v-btn>
                <v-icon left class="mr-2">mdi-plus</v-icon>Ajouter membre
              </v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
  </div>
</template>

<script>
import editVarianteDialog from '@/components/editVarianteDialog.vue'
import editParticipation from '@/components/editParticipation.vue'
export default {
  props: {
    editedVariante: {
      type: Object,
      required: false
    }
  },
  components: { editVarianteDialog, editParticipation },

  data() {
    return {
      editedParticipation: {
        id: -1,
        user_id: -1,
        variante_id: this.editedVariante.id,
        sortie_id: this.editedVariante.sortie_id,
        grimpette: 0,
        descente: 0,
        chute: 0,
        extra: 0,
        malus: 0
      },
      dialogParticipation: false
    }
  },

  methods: {
    deleteVariante(variante) {
      confirm('Confirmer la suppression de la variante') &&
        this.$store.dispatch('deleteVariante', variante).then(() => {})
    },
    addParticipation() {
      this.dialogParticipation = true
    },
    closeParticipation() {
      this.dialogParticipation = false
    }
  }
}
</script>

<style scoped></style>
