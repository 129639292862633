<template>
      <v-container>
    <v-row>
    <v-col>
      <miniStatsCards></miniStatsCards>
     </v-col>
    </v-row>
  <div>
    <v-card width="400" class="mx-auto mt-5">
      <v-card-title>
        <h1 class="display-1">Connexion</h1>
      </v-card-title>
      <v-card-text>
        <v-form @submit.prevent="login">
          <v-text-field label="Email" v-model="email" prepend-icon="mdi-account-circle"></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn type="submit" @click="login" color="info">Connexion</v-btn>
      </v-card-actions>
      <v-alert v-if="message" :type="status">{{message}}</v-alert>
    </v-card>
  </div>
  </v-container>
</template>

<script>
import EventService from '@/services/EventService.js'
import miniStatsCards from '@/components/miniStatsCards.vue'
export default {
  name: 'LoginPage',
  components: {
    miniStatsCards
  },
  data() {
    return {
      message: '',
      email: '',
      status: ''
    }
  },
  methods: {
    login() {
      EventService.passwordlessLogin({
        email: this.email
      })
        .then(response => {
          this.message = response.data.message
          this.status = 'success'
        })
        .catch(err => {
          if (err.response.data.errors == null) {
            this.message = err.response.data.message
          } else {
            this.message = err.response.data.errors.email
          }
          this.status = 'warning'
        })
    }
  }
}
</script>

<style lang="scss" scoped></style>
