import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VRow,[_c(VCol,[_c('miniStatsCards')],1)],1),_c('div',[_c(VCard,{staticClass:"mx-auto mt-5",attrs:{"width":"400"}},[_c(VCardTitle,[_c('h1',{staticClass:"display-1"},[_vm._v("Connexion")])]),_c(VCardText,[_c(VForm,{on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_c(VTextField,{attrs:{"label":"Email","prepend-icon":"mdi-account-circle"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)],1),_c(VDivider),_c(VCardActions,[_c(VBtn,{attrs:{"type":"submit","color":"info"},on:{"click":_vm.login}},[_vm._v("Connexion")])],1),(_vm.message)?_c(VAlert,{attrs:{"type":_vm.status}},[_vm._v(_vm._s(_vm.message))]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }