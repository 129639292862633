import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index.js'
import Classement from '../views/ClassementShow.vue'
import SortieList from '../views/SortieList.vue'
import Reglement from '../views/ReglementDetail.vue'
import PasswordlessLogin from '../views/PasswordlessLogin.vue'
import SortieCreate from '../views/SortieCreate.vue'
import MembreList from '../views/MembreList.vue'
import MembreShow from '../views/MembreShow.vue'
import MembreCreate from '../views/MembreCreate.vue'
import SortieShow from '@/views/SortieShow.vue'
import LoginEmail from '@/views/LoginEmail.vue'
import StatsShow from '@/views/StatsShow.vue'
import  AdminSaisons from '@/views/AdminSaisons.vue'
import  SaisonCreate from '@/views/SaisonCreate.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Classement',
    component: Classement
  },
  {
    path: '/sorties',
    name: 'Sorties',
    component: SortieList
  },
  {
    path: '/sorties/:id',
    name: 'sortie-show',
    component: SortieShow,
    props: true
  },
  {
    path: '/sortie/create',
    name: 'CreationSortie',
    component: SortieCreate,
    beforeEnter(routeTo, routeFrom, next) {
      store.dispatch('fetchVariantes').then(() => {
        store.dispatch('fetchTypeSorties').then(() => {
          store.dispatch('fetchMembres').then(() => {
            next()
          })
        })
      })
    }
  },
  {
    path: '/rules',
    name: 'Reglement',
    component: Reglement,
    meta: {
      auth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: PasswordlessLogin
  },
  {
    path: '/login-email',
    name: 'login-email',
    component: LoginEmail
  },
  {
    path: '/membres',
    name: 'membres',
    component: MembreList
  },
  {
    path: '/membre/create',
    name: 'membre-create',
    component: MembreCreate
  },
  {
    path: '/membre/edit/:id',
    name: 'membre-edit',
    component: MembreCreate,
    props: true
  },
  {
    path: '/membres/:id',
    name: 'membre-show',
    component: MembreShow,
    props: true
  },
  {
    path: '/stats',
    name: 'stats',
    component: StatsShow
  },
  {
    path: '/admin/saisons',
    name: 'admin-saisons',
    component: AdminSaisons
  } ,
   {
    path: '/admin/saison/create',
    name: 'saison-create',
    component: SaisonCreate
  },
  {
    path: '/admin/saison/edit/:id',
    name: 'saison-edit',
    component: SaisonCreate,
    props: true
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('user')
  //to.matched.some(record => record.meta.auth) &&
  const allowedUrl = ['login', 'login-email']
  const test = allowedUrl.indexOf(to.name)
  if (!loggedIn && test < 0) {
    next('/login')
    return
  }
  next()
})

export default router
