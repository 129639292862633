<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Liste des membres
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Recherche"
              single-line
              hide-details
            ></v-text-field>
            <v-btn
              v-permission="'editer membres'"
              absolute
              dark
              fab
              right
              color="pink"
              to="/membre/create"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-card-title>

          <v-data-table
            :loading="loadingMembres"
            :headers="headers"
            :items="membres"
            :items-per-page="20"
            :search="search"
            class="elevation-1 pointer"
            @click:row="goToMember"
          ></v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import router from '@/router'
import { mapState, mapActions } from 'vuex'
export default {
  data: () => ({
    headers: [
      {
        text: 'ID',
        value: 'id'
      },
      {
        text: 'Nom',
        value: 'fullname'
      },
      {
        text: 'Statut',
        value: 'user_status_name'
      }
    ],
    loadingMembres: true,
    search: ''
  }),
  methods: {
    goToMember(item) {
      router.push({ name: 'membre-show', params: { id: item.id } })
    }
  },
  computed: {
    ...mapState(['membres']),
    ...mapActions(['fetchMembres'])
  },
  mounted() {
    this.fetchMembres.then(() => {
      this.loadingMembres = false
    })
  }
}
</script>

<style scoped>
</style>