import Vue from 'vue'
import Vuex from 'vuex'
import EventService from '@/services/EventService.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    membres: [],
    sorties: [],

    typeSorties: [],
    variantes: [],
    saisonActive: -1,
    saisons: []
  },
  mutations: {
    SET_USER_DATA(state, userData) {
      state.user = userData
      localStorage.setItem('user', JSON.stringify(userData))
      Vue.prototype.$laravel.setPermissions(userData.permissions)
      Vue.prototype.$laravel.setRoles(userData.roles)
    },
    CLEAR_USER_DATA() {
      localStorage.removeItem('user')
      //location.reload()
    },

    SET_SAISONS(state, saisons) {
      state.saisons = saisons
    },
    ADD_SAISON(state, saison) {
      state.saisons.unshift(saison)
    },
    UPDATE_SAISON(state, updatedSaison) {
      state.saisons[
        state.saisons.findIndex(saison => saison.id === updatedSaison.id)
      ] = updatedSaison
    },
    ADD_SORTIE(state, sortie) {
      state.sorties.unshift(sortie)
    },
    SET_SORTIES(state, sorties) {
      state.sorties = sorties
    },
    UPDATE_SORTIE(state, updatedSortie) {
      if (state.sorties.length === 0) {
        state.sorties.push(updatedSortie)
      } else {
        Vue.set(
          state.sorties,
          state.sorties.findIndex(sortie => sortie.id === updatedSortie.id),
          updatedSortie
        )
      }
    },
    DELETE_SORTIE(state, deletedSortie) {
      state.sorties.splice(
        state.sorties.findIndex(sortie => sortie.id === deletedSortie.id),
        1
      )
    },
    ADD_MEMBRE(state, membre) {
      state.membres.push(membre)
    },
    SET_VARIANTES(state, variantes) {
      state.variantes = variantes
    },
    SET_MEMBRES(state, membres) {
      state.membres = membres
    },
    UPDATE_MEMBRE(state, updatedMembre) {
      state.membres[
        state.membres.findIndex(membre => membre.id === updatedMembre.id)
      ] = updatedMembre
    },
    SET_TYPE_SORTIES(state, typeSorties) {
      state.typeSorties = typeSorties
    },
    UPDATE_VARIANTE(state, updatedVariante) {
      state.sorties.forEach((sortie, indexSortie) => {
        var indexVariante = sortie.variantes.findIndex(
          variante => variante.id === updatedVariante.id
        )

        if (indexVariante > -1) {
          var updatedSortie = state.sorties[indexSortie]
          updatedSortie.variantes[indexVariante] = updatedVariante
          Vue.set(state.sorties, indexSortie, updatedSortie)
          //  state.sorties[indexSortie].variantes[indexVariante] = updatedVariante
        }
      })
    },
    DELETE_VARIANTE(state, deletedVariante) {
      state.sorties.forEach((sortie, indexSortie) => {
        var indexVariante = sortie.variantes.findIndex(
          variante => variante.id === deletedVariante.id
        )
        if (indexVariante > -1) {
          var newsortie = state.sorties[indexSortie]
          newsortie.variantes.splice(indexVariante, 1)

          Vue.set(state.sorties, indexSortie, newsortie)
        }
      })
    },
    UPDATE_PARTICIPATION(state, updatedParticipation) {
      state.sorties.forEach((sortie, indexSortie) => {
        sortie.variantes.forEach((variante, indexVariante) => {
          if (variante.participants != null) {
            var indexParticipation = variante.participants.findIndex(
              participation => participation.id === updatedParticipation.id
            )
            if (indexParticipation > -1) {
              state.sorties[indexSortie].variantes[indexVariante].participants[
                indexParticipation
              ] = updatedParticipation
            }
          }
        })
      })
    },
    DELETE_PARTICIPATION(state, deletedParticipation) {
      state.sorties.forEach((sortie, indexSortie) => {
        sortie.variantes.forEach((variante, indexVariante) => {
          if (variante.participants != null) {
            var indexParticipation = variante.participants.findIndex(
              participation => participation.id === deletedParticipation.id
            )
            if (indexParticipation > -1) {
              state.sorties[indexSortie].variantes[
                indexVariante
              ].participants.splice(indexParticipation, 1)
            }
          }
        })
      })
    },
    CHANGE_SAISON(state, saisonIndex) {
      state.saisonActive = saisonIndex
    }
  },
  actions: {
    login({ commit }, credentials) {
      return EventService.login(credentials).then(({ data }) => {
        commit('SET_USER_DATA', data)
      })
    },

    loginFromEmail({ commit }, params) {
      return EventService.loginFromEmail(
        params.url,
        params.user_type,
        params.signature
      ).then(({ data }) => {
        commit('SET_USER_DATA', data)
      })
    },
    logout({ commit }) {
      return EventService.logout().then(() => {
        commit('CLEAR_USER_DATA')
      })
    },

    fetchSaisons({ commit }) {
      if (this.state.saisons.length === 0) {
        return EventService.getSaisons().then(response => {
          commit('SET_SAISONS', response.data)
        })
      } else {
        return this.state.saisons
      }
    },
    createSaisons({ commit }, saison) {
      return EventService.addSaison(saison).then(response => {
        commit('ADD_SAISON', response.data)
      })
    },
    updateSaisons({ commit }, saison) {
      return EventService.updateSaison(saison).then(response => {
        commit('UPDATE_SAISON', response.data)
      })
    },
    createSortie({ commit }, sortie) {
      EventService.postSortie(sortie)
        .catch(error => {
          return error
        })
        .then(response => {
          commit('ADD_SORTIE', response.data.data)
        })
    },
    createMembre({ commit }, membre) {
      return EventService.postMembre(membre).then(response => {
        commit('ADD_MEMBRE', response.data.data)
      })
    },
    updateMembre({ commit }, membre) {
      return EventService.updateMembre(membre).then(response => {
        commit('UPDATE_MEMBRE', response.data.data)
      })
    },
    fetchVariantes({ commit }) {
      if (this.state.variantes.length === 0) {
        return EventService.getVariantes().then(response => {
          commit('SET_VARIANTES', response.data)
        })
      } else {
        return this.state.variantes
      }
    },
    fetchMembres({ commit }) {
      if (this.state.membres.length === 0) {
        return EventService.getMembres().then(response => {
          commit('SET_MEMBRES', response.data.data)
        })
      } else {
        return this.state.membres
      }
    },
    fetchSorties({ commit }) {
      var saisonId
      if (this.state.saisonActive === -1) {
        saisonId = this.state.saisons[0].id
      } else {
        saisonId = this.state.saisons[this.state.saisonActive].id
      }

      return EventService.getSorties(saisonId).then(response => {
        commit('SET_SORTIES', response.data.data)
      })
    },
    updateSortie({ commit }, sortie) {
      return EventService.updateSortie(sortie).then(response => {
        commit('UPDATE_SORTIE', response.data.data)
      })
    },
    updateStoreSortie({ commit }, sortie) {
      commit('UPDATE_SORTIE', sortie)
    },
    deleteSortie({ commit }, sortie) {
      EventService.deleteSortie(sortie).then(response => {
        if (response.status == 204) {
          commit('DELETE_SORTIE', sortie)
        }
      })
    },
    fetchTypeSorties({ commit }) {
      if (this.state.typeSorties.length === 0) {
        return EventService.getTypeSorties().then(response => {
          commit('SET_TYPE_SORTIES', response.data)
        })
      } else {
        return this.state.typeSorties
      }
    },
    updateVariante({ commit }, variante) {
      return EventService.updateVariante(variante).then(response => {
        commit('UPDATE_VARIANTE', response.data.data)
      })
    },
    addVariante({ commit }, variante) {
      return EventService.addVariante(variante).then(response => {
        commit('UPDATE_SORTIE', response.data.data)
        return response
      })
    },
    deleteVariante({ commit }, variante) {
      EventService.deleteVariante(variante).then(response => {
        if (response.status == 204) {
          commit('DELETE_VARIANTE', variante)
        }
      })
    },
    updateParticipation({ commit }, participation) {
      return EventService.updateParticipation(participation).then(response => {
        commit('UPDATE_PARTICIPATION', response.data)
      })
    },
    addParticipation({ commit }, participation) {
      return EventService.addParticipation(participation).then(response => {
        commit('UPDATE_SORTIE', response.data.data)
      })
    },
    deleteParticipation({ commit }, participation) {
      EventService.deleteParticipation(participation).then(response => {
        if (response.status == 204) {
          commit('DELETE_PARTICIPATION', participation)
        }
      })
    },
    changeSaison({ commit }, saisonIndex) {
      commit('CHANGE_SAISON', saisonIndex)
    },
    updatePhotosSortie({ commit }, sortie) {
      commit('UPDATE_SORTIE', sortie)
    }
  },
  modules: {},
  getters: {
    getMembreById: state => id => {
      return state.membres.find(membre => membre.id === id)
    },
    getNameSaisonCourante: state => {
      if (state.saisonActive === -1) {
        return state.saisons[0].nom
      } else {
        return state.saisons[state.saisonActive].nom
      }
    },
    getIndexSaisonCourante: state => {
      return state.saisonActive
    },
    gedIdSaisonCourante: state => {
      if (state.saisonActive === -1) {
        return state.saisons[0].id
      } else {
        return state.saisons[state.saisonActive].id
      }
    },
    getSaisonById: state => id => {
      return state.saisons.find(saison => saison.id === id)
    },
    getSortieById: state => ids => {
      return state.sorties.find(sortie => sortie.id === Number(ids))
    },
    isLogged: state => !!state.user,
    getActiveMembers: state => {
      return state.membres.filter(
        membre =>
          membre.user_status_id == 1 ||
          membre.user_status_id == 2 ||
          membre.user_status_id == 3
      )
    },
    getLoggedInUserID: state => {
      return state.user.user.id
    },
    getLoggedInUserPrenom: state => {
      return state.user.user.prenom
    }
  }
})
