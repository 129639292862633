import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VListItem,{attrs:{"link":""}},[_c(VListItemTitle,[_c(VDialog,{attrs:{"max-width":"500px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({on:{"click":function($event){return _vm.editVariante()}}},on),[_c(VIcon,{staticClass:"mr-2",attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v(" "+_vm._s(_vm.action)+" ")],1)]}}]),model:{value:(_vm.dialogVariante),callback:function ($$v) {_vm.dialogVariante=$$v},expression:"dialogVariante"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.action))])]),_c(VCardText,[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VSelect,{attrs:{"label":"Type de variante","items":_vm.variantes,"item-text":"nom","item-value":"id"},model:{value:(_vm.localVariante.type_variante_id),callback:function ($$v) {_vm.$set(_vm.localVariante, "type_variante_id", $$v)},expression:"localVariante.type_variante_id"}}),_c(VTextField,{attrs:{"label":"Distance"},model:{value:(_vm.localVariante.km),callback:function ($$v) {_vm.$set(_vm.localVariante, "km", $$v)},expression:"localVariante.km"}}),_c(VTextField,{attrs:{"label":"Dénivelé"},model:{value:(_vm.localVariante.deniv),callback:function ($$v) {_vm.$set(_vm.localVariante, "deniv", $$v)},expression:"localVariante.deniv"}}),_c(VTextField,{attrs:{"label":"Vitesse"},model:{value:(_vm.localVariante.vitesse),callback:function ($$v) {_vm.$set(_vm.localVariante, "vitesse", $$v)},expression:"localVariante.vitesse"}})],1)],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.closeVariante(_vm.localVariante)}}},[_vm._v("Enregistrer")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }