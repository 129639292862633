<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="8">
        <v-sheet cols="12">
          <v-skeleton-loader type="heading" :loading="loadingSorties">
            <h1>
              Liste des sorties pour la saison {{ getNameSaisonCourante }}
            </h1>
          </v-skeleton-loader>
          <v-btn
            v-permission="'editer sorties'"
            absolute
            dark
            fab
            right
            color="pink"
            to="/sortie/create"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-sheet>
      </v-col>
    </v-row>
    <v-row v-for="sortie in sorties" :key="`${sortie.id}`" justify="center">
      <v-col cols="12" md="8">
        <v-skeleton-loader type="card" :loading="loadingSorties">
          <miniCardSortie :sortie="sortie" />
        </v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import miniCardSortie from '@/components/miniCardSortie.vue'
import { mapState, mapActions, mapGetters } from 'vuex'
export default {
  components: { miniCardSortie },
  data: () => ({
    loadingSorties: true
  }),
  computed: {
    ...mapState(['sorties']),
    ...mapActions([
      'fetchSorties',
      'fetchTypeSorties',
      'fetchVariantes',
      'fetchMembres',
      'fetchSaisons'
    ]),
    ...mapGetters(['getNameSaisonCourante', 'getIndexSaisonCourante'])
  },
  mounted() {
    this.fetchSaisons.then(() => {
      this.fetchSorties.then(() => {
        this.loadingSorties = false
        this.fetchTypeSorties.then(() => {
          this.fetchVariantes.then(() => {
            this.fetchMembres
          })
        })
      })
    })
  },
  watch: {
    getIndexSaisonCourante() {
      this.loadingSorties = true
      this.fetchSorties.then(() => {
        this.loadingSorties = false
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
