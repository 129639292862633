import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VRow,{attrs:{"justify-center":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{attrs:{"loading":_vm.loading}},[(_vm.id)?_c(VCardTitle,[_c('h1',[_vm._v("Modification saison")])]):_c(VCardTitle,[_c('h1',[_vm._v("Nouvelle saison")])]),_c(VCardText,[_c(VForm,{ref:"newSaison",on:{"submit":function($event){$event.preventDefault();return _vm.createSaison.apply(null, arguments)}}},[_c(VTextField,{attrs:{"name":"Nom","label":"Nom","id":"nom"},model:{value:(_vm.newSaison.nom),callback:function ($$v) {_vm.$set(_vm.newSaison, "nom", $$v)},expression:"newSaison.nom"}}),_c(VBtn,{staticClass:"mr-4",attrs:{"type":"submit","color":"primary"}},[_vm._v("Soumettre")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }