<template>
  <div>
    <v-card class="mx-auto mt-5">
      <v-card-title primary-title>Photos de la sortie</v-card-title>
      <v-card-text>
        <div v-if="sortie.photos.length > 0">
          <v-carousel cycle height="500" hide-delimiter-background show-arrows-on-hover>
            <v-carousel-item v-for="photo in sortie.photos" :key="photo.id">
              <div class="container">
                <a :href="createUrl(photo.filename)" target="_blank">
                  <v-img max-height="500" :src="createUrl(photo.thumbnail)"></v-img>
                </a>
                <div class="bottom-left">Envoyée par {{ photo.user_name }}</div>
                <div v-permission="'editer sorties'" class="upper-right">
                  <a href="#" v-on:click="deletePhoto(photo.id)">Supprimer</a>
                </div>
              </div>
            </v-carousel-item>
          </v-carousel>
          <v-container>
            <v-layout align-center>
              <v-flex></v-flex>
            </v-layout>
          </v-container>
        </div>
        <template>
          <div v-cloak @drop.prevent="addDropFile" @dragover.prevent>
            <v-file-input
              ref="files"
              accept=".jpg"
              label="Vous pouvez envoyer des photos au format .jpg (selectionnez plusieurs photos en maintenant ctrl enfoncé)"
              v-model="files"
              multiple
              chips
              :error-messages="error"
              counter
              prepend-icon="mdi-camera"
            ></v-file-input>
          </div>
          <v-btn
            class="mr-4"
            @click="envoiPhotos"
            color="primary"
            :loading="loading"
            :disabled="disabled"
          >Soumettre</v-btn>

          <v-alert v-if="message" type="info" class="float-right">
            {{
            message
            }}
          </v-alert>
        </template>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import EventService from '@/services/EventService.js'
import { mapActions } from 'vuex'

export default {
  props: {
    sortie: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      files: null,
      error: '',
      loading: false,
      disabled: false,
      message: '',
      rules: [
        value =>
          !value ||
          value.size < 30000000000 ||
          'Le gpx doit faire moins de 3 MB!'
      ]
    }
  },
  computed: {
    ...mapActions(['updateSortie'])
  },
  methods: {
    envoiPhotos() {
      if (this.files) {
        this.loading = true
        this.disabled = true
        let formData = new FormData()
        for (var i = 0; i < this.files.length; i++) {
          let file = this.files[i]

          formData.append('files[' + i + ']', file)
        }

        EventService.envoiPhoto(this.sortie.id, formData)
          .then(response => {
            this.$store.dispatch('updatePhotosSortie', response.data.data)
            this.files = null
            this.loading = false
            this.disabled = false
            this.message = 'Envoi réussi'
          })
          .catch(error => {
            this.error = error.response.data.message
            this.loading = false
            this.disabled = false
            this.message = "Erreur lors de l'envoi"
          })
      }
    },
    addDropFile(e) {
      this.files = Array.from(e.dataTransfer.files)
    },
    createUrl(fileName) {
      return process.env.VUE_APP_API_URL + '/storage/' + fileName
    },
    deletePhoto(id) {
      confirm('Confirmer la suppression de la photo') &&
        EventService.deletePhoto(id).then(response => {
          this.$store.dispatch('updatePhotosSortie', response.data.data)
        })
    }
  }
}
</script>

<style scoped>
.bottom-left {
  position: absolute;
  bottom: 25px;
  left: 16px;
}
.upper-right {
  position: absolute;
  top: 25px;
  right: 16px;
}
.container {
  position: relative;
  text-align: center;
  color: white;
}
</style>
