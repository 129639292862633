<template>
  <div>
    <v-menu v-permission="'editer sorties'">
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item link>
          <v-list-item-title>
            <v-dialog v-model="dialog" max-width="500px" persistent>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on">
                  <v-icon left class="mr-2">mdi-pencil</v-icon>Modifier
                </v-btn>
              </template>

              <v-card>
                <v-card-title>
                  <span class="headline">Modification de sortie</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="sortietoedit.nom"
                          label="Nom"
                        ></v-text-field>
                        <v-text-field
                          v-model="sortietoedit.lieu"
                          label="Lieu"
                        ></v-text-field>
                        <v-menu
                          v-model="menu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="sortietoedit.date"
                              label="Date de la sortie"
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="sortietoedit.date"
                            @input="menu = false"
                          ></v-date-picker>
                        </v-menu>

                        <v-select
                          v-model="sortietoedit.type_sortie_id"
                          label="Type de sortie"
                          :items="typeSorties"
                          item-text="nom"
                          item-value="id"
                        ></v-select>
                        <v-switch
                          label="Point Rando"
                          v-model="sortietoedit.pointRando"
                        ></v-switch>

                        <v-switch
                          label="Point Mauvais temps"
                          v-model="sortietoedit.pointMauvaisTemps"
                        ></v-switch>
                        <v-textarea
                          label="Commentaire"
                          type="textarea"
                          v-model="sortietoedit.commentaire"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close"
                    >Enregistrer</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-list-item-title>
        </v-list-item>
        <v-list-item link>
          <v-list-item-title @click="deleteSortie(sortietoedit)">
            <v-btn> <v-icon left>mdi-delete</v-icon>Supprimer </v-btn>
          </v-list-item-title>
        </v-list-item>
        <editVarianteDialog
          action="Ajouter Variante"
          :sortietoedit="sortietoedit"
        ></editVarianteDialog>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import router from '@/router'
import { mapState, mapActions } from 'vuex'
import editVarianteDialog from '@/components/editVarianteDialog.vue'

export default {
  props: {
    sortie: {
      type: Object,
      required: true
    }
  },
  components: {
    editVarianteDialog
  },
  data() {
    return {
      dialog: false,
      menu: false, 
      sortietoedit: {...this.sortie}
    }
  },
  computed: {
    ...mapState(['typeSorties']),
    ...mapActions(['updateSortie', 'fetchTypeSorties'])
  },
  methods: {
    close() {
      this.sortietoedit.type_sortie_nom = this.typeSorties.find(
        obj => obj.id == this.sortietoedit.type_sortie_id
      ).nom
      this.$store.dispatch('updateSortie', this.sortietoedit).then(() => {
        this.dialog = false
        this.menu = false
      })
    },
    deleteSortie(sortie) {
      confirm('Are you sure you want to delete this item?') &&
        this.$store.dispatch('deleteSortie', sortie).then(() => {
          router.push({ name: 'Sorties' })
        })
    }
  },
  created() {
    this.fetchTypeSorties
  }
}
</script>

<style lang="scss" scoped></style>
