<template>
  <v-row >
    <v-col>

      <v-row>
      <v-col>
        <v-skeleton-loader type="card" :loading="loadingStats">
          <v-card>
            <v-card-title class="text-center">Sorties cette saison</v-card-title>
            <v-card-text class="display-2 text-center">{{getNbsorties}}</v-card-text>   
          </v-card>
      </v-skeleton-loader>
      </v-col>
      <v-col>
        <v-skeleton-loader type="card" :loading="loadingStats">
          <v-card>
            <v-card-title>Kilomètres parcourus</v-card-title>
            <v-card-text class="display-2 text-center">{{getKm}}</v-card-text>
          </v-card>
      </v-skeleton-loader>
      </v-col>
      <v-col>
        <v-skeleton-loader type="card" :loading="loadingStats">
          <v-card>
            <v-card-title>Dénivelé grimpé</v-card-title>
            <v-card-text class="display-2 text-center">{{getDeniv}}</v-card-text>
          </v-card>
      </v-skeleton-loader>
      </v-col>
      <v-col>
        <v-skeleton-loader type="card" :loading="loadingStats">
          <v-card>
            <v-card-title>Fréquentation moyenne</v-card-title>
            <v-card-text class="display-2 text-center">{{ getPart }}</v-card-text>
          </v-card>
        </v-skeleton-loader>
      </v-col>
    </v-row>

    </v-col>
  </v-row>
</template>

<script>
// import router from '@/router'
import { mapGetters } from 'vuex'
import EventService from '@/services/EventService.js'

export default {
  name: 'miniStatsCards',

  data: () => ({
    stats: [],
    loadingStats: true
  }),
  created: function() {
    this.$store.dispatch('fetchSaisons').then(() => {
      EventService.getStats(this.gedIdSaisonCourante).then(
        response => {
          this.stats = response.data
          this.loadingStats = false
        }
      )
    })
  },
  computed: {
    ...mapGetters(['getNameSaisonCourante','gedIdSaisonCourante']),
    getNameSaisonCourante() {
      return this.$store.getters.getNameSaisonCourante
    },
    getNbsorties() {
      return this.stats.nbSortie
    },
    getKm() {
      return this.stats.kmTotal
    },
    getDeniv() {
      return this.stats.denivCumul
    },
    getPart() {
      return this.stats.participationMoyenne
    }

  }
}
</script>

<style scoped>
</style>
