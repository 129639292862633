<template>
  <v-card>
    <v-card-title>
      Classement {{ getNameSaisonCourante }}
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Recherche"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="classement"
      :items-per-page="10"
      class="pointer"
      @click:row="selectRow"
      :search="search"
      :mobile-breakpoint="0"
    ></v-data-table>
  </v-card>
</template>

<script>
import router from '@/router'
//import { mapGetters } from 'vuex'
export default {
  name: 'tableauClassement',

  props: {
    classement: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    search: '',
    headers: [
      { text: 'Position', value: 'position', width: 50, align: 'center' },
      { text: 'Prenom', value: 'fullname' },
      { text: 'Présence', value: 'presence' },
      { text: 'Points', value: 'total' },
      { text: 'Participation', value: 'txParticipation' }
    ]
  }),
  computed: {
    //...mapGetters(['getNameSaisonCourante'])
    getNameSaisonCourante() {
      return this.$store.getters.getNameSaisonCourante
    }
  },
  methods: {
    selectRow(membre) {
      router.push({ name: 'membre-show', params: { id: membre.id } })
    }
  }
}
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
