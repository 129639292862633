<template>
  <v-container>
    <v-row justify-center>
      <v-col cols="12">
        <v-card :loading="loading">
          <v-card-title v-if="id">
            <h1>Modification saison</h1>
          </v-card-title>
          <v-card-title v-else>
            <h1>Nouvelle saison</h1>
          </v-card-title>
          <v-card-text>
            <v-form ref="newSaison" @submit.prevent="createSaison">

              <v-text-field name="Nom" label="Nom" id="nom" v-model="newSaison.nom"></v-text-field>
         
              <v-btn class="mr-4" type="submit" color="primary">Soumettre</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
//import { mapGetters } from 'vuex'
import store from '@/store'
import { mapActions } from 'vuex'
import router from '@/router'
export default {
  props: {
    id: {
      required: false
    }
  },
  data() {
    return {
      newSaison: {},
      loading: true,
    }
  },
  methods: {
    initEmptySaison() {
      if (this.id == null) {
        return {
          name: '',
        }
      } else {
        return store.getters.getSaisonById(Number(this.id))
      }
    },
    createSaison() {
      if (this.id == null) {
        this.$store.dispatch('createSaisons', this.newSaison).then(() => {
          this.newSaison = this.initEmptySaison()
          router.push({ name: 'admin-saisons' })
        })
      } else {
        this.$store.dispatch('updateSaisons', this.newSaison).then(() => {
          this.newSaison = this.initEmptySaison()
          router.push({ name: 'admin-saisons'})
        })
      }
    }
  },
  computed: {
    ...mapActions(['fetchSaisons'])
  },
  created() {
    if (!this.$laravel.hasPermission('editer membres')) {
      // redirect
      router.push({ name: 'Classement' })
    }

    this.fetchSaisons
      .then(() => {
        this.loading = false
      })
      .then(() => {
        this.newSaison = this.initEmptySaison()
      })
  }
}
</script>

<style scoped>
</style>