<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="8">
        <v-skeleton-loader type="card" :loading="loading">
          <fullCardSortie :sortie="getSortieById(id)" />
        </v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import fullCardSortie from '@/components/fullCardSortie.vue'
import EventService from '@/services/EventService.js'
import { mapGetters } from 'vuex'

export default {
  components: { fullCardSortie },
  props: ['id'],
  data: () => ({
    loading: true
  }),
  computed: {
    ...mapGetters(['getSortieById'])
  },
  mounted() {
    if (this.$store.getters.getSortieById(this.id) == null) {
      EventService.getSortie(this.id).then(reponse => {
        this.$store
          .dispatch('updateStoreSortie', reponse.data.data)
          .then(() => {
            this.loading = false
          })
      })
    } else {
      if (this.$store.getters.getSortieById(this.id).variantes[0] == null) {
        EventService.getSortie(this.id).then(reponse => {
          this.$store
            .dispatch('updateStoreSortie', reponse.data.data)
            .then(() => {
              this.loading = false
            })
        })
      } else {
        if (
          this.$store.getters.getSortieById(this.id).variantes[0]
            .participants == null
        ) {
          EventService.getSortie(this.id).then(reponse => {
            this.$store
              .dispatch('updateStoreSortie', reponse.data.data)
              .then(() => {
                this.loading = false
              })
          })
        } else {
          this.loading = false
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
