<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="8">
        <v-card>
          <v-card-title>
            <h1>Nouvelle sortie</h1>
          </v-card-title>
          <v-card-text>
            <v-form ref="nouvelleSortie" v-model="formValidity" @submit.prevent="createSortie">
              <v-stepper v-model="e1">
                <v-stepper-header>
                  <v-stepper-step :complete="e1 > 1" step="1">Infos générales</v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step :complete="e1 > 2" step="2">Variantes et participants</v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                  <v-stepper-content step="1">
                    <v-menu
                      v-model="menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                      :rules="notEmpty"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="newsortie.date"
                          label="Date de la sortie"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="newsortie.date" @input="menu = false"></v-date-picker>
                    </v-menu>

                    <v-text-field
                      label="Nom de la sortie"
                      type="text"
                      v-model="newsortie.nom"
                      :rules="notEmpty"
                      required
                    ></v-text-field>

                    <v-text-field
                      label="Lieu de la sortie"
                      type="text"
                      v-model="newsortie.lieu"
                      :rules="notEmpty"
                      required
                    ></v-text-field>

                    <v-textarea
                      label="Commentaire"
                      type="textarea"
                      v-model="newsortie.commentaire"
                      :rules="notEmpty"
                      required
                    ></v-textarea>

                    <v-autocomplete
                      v-model="newsortie.type_sortie_id"
                      label="Type de sortie"
                      :items="typeSorties"
                      item-text="nom"
                      item-value="id"
                      @change="updateVarianteList"
                    ></v-autocomplete>

                    <v-switch label="Point Rando" v-model="newsortie.pointRando"></v-switch>

                    <v-switch label="Point Mauvais temps" v-model="newsortie.pointMauvaisTemps"></v-switch>

                    <v-btn color="primary" @click="e1 = 2">Continue</v-btn>
                  </v-stepper-content>

                  <v-stepper-content step="2">
                    <v-sheet
                      v-for="(newvariante, varianteIndex) in newVariantes"
                      :key="newvariante.id"
                    >
                      <v-switch
                        :label="newvariante.nom"
                        v-model="newvariante.active"
                        @change="
                          createVariante(newvariante.active, newvariante.id)
                        "
                      ></v-switch>
                      <v-sheet v-if="newVariantes[varianteIndex].active">
                        <v-text-field
                          label="Distance"
                          type="text"
                          v-model="
                            newsortie.variantes[getVarianteId(newvariante.id)]
                              .km
                          "
                          :rules="notEmpty"
                          required
                        ></v-text-field>

                        <v-text-field
                          label="Vitesse"
                          type="text"
                          v-model="
                            newsortie.variantes[getVarianteId(newvariante.id)]
                              .vitesse
                          "
                          :rules="notEmpty"
                          required
                        ></v-text-field>
                        <v-text-field
                          label="Dénivelé"
                          type="text"
                          v-model="
                            newsortie.variantes[getVarianteId(newvariante.id)]
                              .deniv
                          "
                          :rules="notEmpty"
                          required
                        ></v-text-field>
                        <v-autocomplete
                          v-model="
                            newsortie.variantes[getVarianteId(newvariante.id)]
                              .participantIdList
                          "
                          :items="getActiveMembers"
                          filled
                          clearable
                          chips
                          color="blue-grey lighten-2"
                          label="Participants"
                          item-text="fullname_forum"
                          item-value="id"
                          multiple
                          hide-selected
                          :search-input.sync="searchInput"
                          @change="updateParticipantList(newvariante.id)"
                        >
                          <template v-slot:selection="participant">
                            <v-chip
                              v-bind="
                                newsortie.variantes[
                                  getVarianteId(newvariante.id)
                                ].participantIdList
                              "
                              :input-value="participant.selected"
                              close
                              @click="participant.select"
                              @click:close="
                                removeParticipantFromVariante(
                                  newvariante.id,
                                  participant.item
                                )
                              "
                            >{{ participant.item.fullname }}</v-chip>
                          </template>

                          <template v-slot:item="data">
                            <template>
                              <v-list-item-content v-text="data.item.fullname_forum"></v-list-item-content>
                            </template>
                          </template>
                        </v-autocomplete>
                        <div
                          v-if="
                            newvariante.active &&
                              newsortie.variantes[getVarianteId(newvariante.id)]
                                .participantIdList != null
                          "
                        >
                          <v-sheet
                            v-for="participant in newsortie.variantes[
                              getVarianteId(newvariante.id)
                            ].participantIdList"
                            :key="participant"
                          >
                            <v-row>
                              <v-col cols="4" md="2">
                                {{
                                getMembreById(participant).fullname
                                }}
                              </v-col>
                              <v-col cols="4" md="2">
                                <v-text-field
                                  label="Pt grimpette"
                                  type="text"
                                  v-model="
                                    newsortie.variantes[
                                      getVarianteId(newvariante.id)
                                    ].participants[
                                      getMemberIndexFromId(
                                        getVarianteId(newvariante.id),
                                        getMembreById(participant).id
                                      )
                                    ].grimpette
                                  "
                                ></v-text-field>
                              </v-col>
                              <v-col cols="4" md="2">
                                <v-text-field
                                  label="Pt descente"
                                  type="text"
                                  v-model="
                                    newsortie.variantes[
                                      getVarianteId(newvariante.id)
                                    ].participants[
                                      getMemberIndexFromId(
                                        getVarianteId(newvariante.id),
                                        getMembreById(participant).id
                                      )
                                    ].descente
                                  "
                                ></v-text-field>
                              </v-col>
                              <v-col cols="4" md="2">
                                <v-text-field
                                  label="Pt chute"
                                  type="text"
                                  v-model="
                                    newsortie.variantes[
                                      getVarianteId(newvariante.id)
                                    ].participants[
                                      getMemberIndexFromId(
                                        getVarianteId(newvariante.id),
                                        getMembreById(participant).id
                                      )
                                    ].chute
                                  "
                                ></v-text-field>
                              </v-col>
                              <v-col cols="4" md="2">
                                <v-text-field
                                  label="Pt extra"
                                  type="text"
                                  v-model="
                                    newsortie.variantes[
                                      getVarianteId(newvariante.id)
                                    ].participants[
                                      getMemberIndexFromId(
                                        getVarianteId(newvariante.id),
                                        getMembreById(participant).id
                                      )
                                    ].extra
                                  "
                                ></v-text-field>
                              </v-col>
                              <v-col cols="4" md="2">
                                <v-text-field
                                  label="Pt malus"
                                  type="text"
                                  v-model="
                                    newsortie.variantes[
                                      getVarianteId(newvariante.id)
                                    ].participants[
                                      getMemberIndexFromId(
                                        getVarianteId(newvariante.id),
                                        getMembreById(participant).id
                                      )
                                    ].malus
                                  "
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-divider></v-divider>
                          </v-sheet>
                        </div>
                      </v-sheet>
                    </v-sheet>

                    <v-btn
                      class="mr-4"
                      type="submit"
                      color="primary"
                      :disabled="!formValidity"
                    >Soumettre</v-btn>
                    <v-btn class="mr-4" color="warning" @click="resetValidation">Réinit Validations</v-btn>
                    <v-btn color="error" @click="resetForm">Vider</v-btn>
                    <v-btn color="primary" @click="e1 = 1">Retour</v-btn>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import router from '@/router'

export default {
  data() {
    return {
      newsortie: this.createNewSortie(),
      newVariantes: [],

      menu: false,
      e1: 1,
      notEmpty: [value => !!value || 'Le champ ne doit pas être vide'],
      formValidity: false,
      searchInput: ''
    }
  },
  methods: {
    createNewVariantes() {
      var initvariantes = JSON.parse(
        JSON.stringify(this.$store.state.variantes)
      )
      initvariantes.forEach(variant => {
        variant.active = false
      })
      return initvariantes
    },
    updateVarianteList() {
      var oldVariantes = this.newVariantes
      // Le choix est un projet, raid ou séjour
      if (this.newsortie.type_sortie_id === 11) {
        this.newVariantes = [
          oldVariantes.find(variante => variante.id === 6),
          oldVariantes.find(variante => variante.id === 7),
          oldVariantes.find(variante => variante.id === 8),
          oldVariantes.find(variante => variante.id === 9),
          oldVariantes.find(variante => variante.id === 10),
          oldVariantes.find(variante => variante.id === 11)
        ]
      }
      // le choix est un bénévolat
      else if (this.newsortie.type_sortie_id === 15) {
        this.newVariantes = [oldVariantes.find(variante => variante.id === 5)]
      }
      // sortie standard
      else {
        this.newVariantes = [
          oldVariantes.find(variante => variante.id === 1),
          oldVariantes.find(variante => variante.id === 2),
          oldVariantes.find(variante => variante.id === 3),
          oldVariantes.find(variante => variante.id === 4)
        ]
      }
    },

    resetValidation() {
      this.$refs.nouvelleSortie.resetValidation()
    },
    resetForm() {
      this.$refs.nouvelleSortie.reset()
    },

    createSortie() {
      this.$store
        .dispatch('createSortie', this.newsortie)
        .catch(() => {
          return
        })
        .then(() => {
          this.newsortie = this.createNewSortie()
          this.newVariantes = this.createNewVariantes()
          router.push({ name: 'Sorties' })
        })
      //
    },
    createNewSortie() {
      const id = Math.floor(Math.random() * 10000000)
      return {
        id: id,
        nom: '',
        lieu: '',
        date: '',
        commentaire: '',
        type_sortie_id: '',
        saison_id: this.$store.getters.gedIdSaisonCourante,
        pointRando: false,
        pointMauvaisTemps: false,
        variantes: []
      }
    },
    createVariante(state, id) {
      // l'index 6 en fait aussi partie mais je ne veux pas le comportement de copier coller de membre sur cette premiere saisie
      const indexVarianteProjet = [7, 8, 9, 10, 11]
      if (state) {
        if (!this.newsortie.variantes.find(variante => variante.id === id)) {
          // variante bénévolat présaisie à 0
          if (id === 5) {
            this.newsortie.variantes.push({
              id: id,
              km: '',
              deniv: '',
              vitesse: '',
              participantIdList: [],
              participants: []
            })
            // variante de type projet à partir du J2 : récupérer la liste des membres du premier jour
          } else if (indexVarianteProjet.includes(id)) {
            this.newsortie.variantes.push({
              id: id,
              km: '',
              deniv: '',
              vitesse: '',
              participantIdList: JSON.parse(
  JSON.stringify(
    this.newsortie.variantes.find(
                variante => variante.id === 6
              ).participantIdList)),
              participants: JSON.parse(
  JSON.stringify(this.newsortie.variantes.find(
                variante => variante.id === 6
              ).participants))
            })
            // sortie standard
          } else {
            this.newsortie.variantes.push({
              id: id,
              km: '',
              deniv: '',
              vitesse: '',
              participantIdList: [],
              participants: []
            })
          }
        }
      } else {
        var varianteIndex = this.newsortie.variantes.findIndex(
          variante => variante.id === id
        )
        if (varianteIndex > -1) {
          this.newsortie.variantes.splice(varianteIndex, 1)
        }
      }
    },
    getVarianteId(idVariante) {
      const retour = this.newsortie.variantes.findIndex(
        variante => variante.id === idVariante
      )
      return retour
    },
    getMemberIndexFromId(posVariante, idMembre) {
      return this.newsortie.variantes[posVariante].participants.findIndex(
        parti => parti.id === idMembre
      )
    },

    removeParticipantFromVariante(variante, participant) {
      const index = this.newsortie.variantes[
        this.getVarianteId(variante)
      ].participantIdList.indexOf(participant.id)
      if (index >= 0)
        this.newsortie.variantes[
          this.getVarianteId(variante)
        ].participantIdList.splice(index, 1)

      const index2 = this.newsortie.variantes[
        this.getVarianteId(variante)
      ].participants.findIndex(parti => parti.id === participant.id)
      if (index2 >= 0)
        this.newsortie.variantes[
          this.getVarianteId(variante)
        ].participants.splice(index2, 1)
    },
    updateParticipantList(varianteId) {
      //add new participant
      for (var participant in this.newsortie.variantes[
        this.getVarianteId(varianteId)
      ].participantIdList) {
        if (
          this.newsortie.variantes[
            this.getVarianteId(varianteId)
          ].participants.findIndex(
            parti =>
              parti.id ===
              this.newsortie.variantes[this.getVarianteId(varianteId)]
                .participantIdList[participant]
          ) == -1
        ) {
          // sortie bénévolat : présaisie du pt extra
          if (this.newsortie.type_sortie_id === 15) {
            this.newsortie.variantes[
              this.getVarianteId(varianteId)
            ].participants.push({
              id: this.newsortie.variantes[this.getVarianteId(varianteId)]
                .participantIdList[participant],
              grimpette: 0,
              descente: 0,
              chute: 0,
              extra: 1,
              malus: 0
            })
          } else {
            this.newsortie.variantes[
              this.getVarianteId(varianteId)
            ].participants.push({
              id: this.newsortie.variantes[this.getVarianteId(varianteId)]
                .participantIdList[participant],
              grimpette: 0,
              descente: 0,
              chute: 0,
              extra: 0,
              malus: 0
            })
          }
        }
      }

      // remove participant

      this.newsortie.variantes[
        this.getVarianteId(varianteId)
      ].participants.forEach(participant2 => {
        if (
          this.newsortie.variantes[
            this.getVarianteId(varianteId)
          ].participantIdList.indexOf(participant2.id) == -1
        ) {
          //remove
          this.removeParticipantFromVariante(varianteId, participant2)
        }
      })

      // clear search inpu
      this.searchInput = ''
    }
  },
  computed: {
    ...mapState(['membres', 'typeSorties', 'variantes']),
    ...mapGetters(['getMembreById', 'gedIdSaisonCourante', 'getActiveMembers']),
    ...mapActions(['fetchVariantes'])
  },
  created() {
    if (!this.$laravel.hasPermission('editer sorties')) {
      // redirect
      router.push({ name: 'Sorties' })
    }

    this.newVariantes = this.createNewVariantes()
  }
}
</script>

<style lang="scss" scoped></style>
