<template>
  <div>
    <editParticipation
      :participation="editedParticipation"
      action="Editer Participation"
      :dialogParticipation="dialogParticipation"
      @closeParticipation="closeParticipation"
    ></editParticipation>
    <v-card class="mx-auto mt-5" :loading="loading">
      <v-card-title>
        {{ sortie.nom }}
        <v-spacer></v-spacer>
        <editSortie :sortie="sortie"></editSortie>
      </v-card-title>
      <v-card-subtitle>
        <v-row>
          <v-col cols="3">
            <v-icon>mdi-map-marker</v-icon>
            {{ sortie.lieu }}
          </v-col>
          <v-col cols="3">
            <v-icon>mdi-calendar</v-icon>
            {{ sortie.date }}
          </v-col>
          <v-col cols="4">
            <v-icon>mdi-routes</v-icon>
            {{ sortie.type_sortie_nom }}
          </v-col>
          <v-col cols="1" v-if="sortie.pointRando">
            <v-icon color="green" title="Point rando accordé"
              >mdi-hiking</v-icon
            >
          </v-col>
          <v-col cols="1" v-if="sortie.pointMauvaisTemps">
            <v-icon color="green" title="Point mauvais temps accordé"
              >mdi-weather-pouring</v-icon
            >
          </v-col>
        </v-row>
      </v-card-subtitle>
      <v-card-text>
        <p class="commentaire">Commentaire : {{ sortie.commentaire }}</p>

        <v-sheet v-for="variante in sortie.variantes" :key="variante.id">
          <v-divider></v-divider>
          <v-row>
            <v-col>
              <v-icon>mdi-chart-sankey-variant</v-icon>
              Version {{ variante.type_variante_nom }}
            </v-col>
            <v-col>
              <v-icon>mdi-account</v-icon>
              {{ variante.participants.length }}
            </v-col>
            <v-col>
              <v-icon>mdi-map-marker-distance</v-icon>
              {{ variante.km }} km
            </v-col>
            <v-col>
              <v-icon>mdi-image-filter-hdr</v-icon>
              {{ variante.deniv }} m
            </v-col>
            <v-col>
              <v-icon>mdi-speedometer</v-icon>
              {{ variante.vitesse }} km/h
            </v-col>
            <v-col cols="2" v-if="variante.point_km === 1">
              <v-icon color="green" title="Point KM"
                >mdi-sign-direction-plus</v-icon
              >
            </v-col>
            <v-col cols="2" v-if="variante.point_km === 2">
              <v-icon color="green" title="Point KM"
                >mdi-sign-direction-plus</v-icon
              >
              <v-icon color="green" title="Point KM"
                >mdi-sign-direction-plus</v-icon
              >
            </v-col>
            <v-spacer></v-spacer>

            <editVariante :editedVariante="variante"></editVariante>
          </v-row>
          <v-row>
            <v-data-table
              :headers="headers"
              :items="variante.participants"
              hide-default-footer
              disable-pagination
            >
              <template v-slot:[`item.nom`]="{ item }">
                <router-link
                  :to="{ name: 'membre-show', params: { id: item.id_membre } }"
                  >{{ item.fullname }}</router-link
                >
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                  v-permission="'editer sorties'"
                  small
                  class="mr-2"
                  @click="openParticipation(item)"
                  >mdi-pencil</v-icon
                >
                <v-icon
                  v-permission="'editer sorties'"
                  small
                  @click="deleteParticipation(item)"
                  >mdi-delete</v-icon
                >
              </template>
            </v-data-table>
          </v-row>
          <gpxSortie :variante="variante" class="mb-5"></gpxSortie>
        </v-sheet>
      </v-card-text>
    </v-card>

    <photoSortie :sortie="sortie"></photoSortie>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import gpxSortie from '@/components/gpxSortie.vue'
import editVariante from '@/components/editVariante.vue'

import editParticipation from '@/components/editParticipation.vue'
import editSortie from '@/components/editSortie.vue'
import photoSortie from '@/components/photoSortie.vue'
export default {
  props: {
    sortie: {
      type: Object,
      required: true
    }
  },
  components: {
    gpxSortie,
    editVariante,
    editParticipation,
    editSortie,
    photoSortie
  },
  data() {
    return {
      loading: false,

      dialogParticipation: false,

      loadingTypeSorties: true,
      editedParticipation: {},

      newParticipation: {},
      headers: [
        {
          text: 'Nom',
          value: 'nom'
        },
        {
          text: 'Grimpette',
          value: 'grimpette'
        },
        {
          text: 'Descente',
          value: 'descente'
        },
        {
          text: 'Chute',
          value: 'chute'
        },
        {
          text: 'Extra',
          value: 'extra'
        },
        {
          text: 'Malus',
          value: 'malus'
        },
        { text: 'Actions', value: 'actions', sortable: false }
      ]
    }
  },
  computed: {
    ...mapState(['variantes', 'membres']),
    ...mapActions(['updateSortie', 'updateVariante'])
  },
  methods: {
    openParticipation(participation) {
      this.editedParticipation = participation
      this.dialogParticipation = true
    },
    closeParticipation() {
      this.dialogParticipation = false
    },
    deleteParticipation(participation) {
      confirm('Are you sure you want to delete this item?') &&
        this.$store.dispatch('deleteParticipation', participation)
    }
  }
}
</script>

<style scoped>
.commentaire {
  white-space: pre-line;
}
</style>
