<template>
  <v-container>
    <h1>Règlement du Classement Interne</h1>
    <p>Version d'Octobre 2018</p>
    <h2>Article 1 : Présence</h2>
    <p>Un point pour chaque présence à une activité sportive. Si lors d’une sortie la personne abandonne, elle perd son point de présence lié à cette sortie. Les points présences comptent double dans le total des points.</p>

    <h2>Article 2 : Mauvais temps</h2>
    <p>Point gagné uniquement lorsque le temps est assez mauvais pour dissuader la participation, mais que certains membres sortent quand même (pluie, vent, boue, températures inférieures à 0°).</p>

    <h2>Article 3 : Tranche kilomètre</h2>
    <p>Nous comptons un point Tranche kilomètres par tranche de 10 km effectués à partir de 40 kilomètres et ce dans la limite de deux tranches par sortie. Il est possible d’attribuer un ou deux points de manière forfaitaire pour les séjours où nous faisons beaucoup de distance. C’est au CoDir que revient cette décision.</p>

    <h2>Article 4 : Randos</h2>
    <p>Un point rando est donné pour la participation d’une personne à une randonnée VTT non-organisée par le Club ou à un évènement déclaré comme particulier par le CoDir.</p>

    <h2>Article 4 : Randos</h2>
    <p>Un point rando est donné pour la participation d’une personne à une randonnée VTT non-organisée par le Club ou à un évènement déclaré comme particulier par le CoDir.</p>

    <h2>Article 5 : Grimpette</h2>
    <p>Un seul point par montée et par sortie pourra être gagné. Le point sera accordé uniquement sur les montées vraiment difficiles : 2/3 des personnes présentes n’y arrivent pas, sans mettre pied à terre. Le CoDir doit statuer sur la validité du point une fois que tout le groupe aura franchi la difficulté.</p>

    <h2>Article 6 : Descente</h2>
    <p>Un seul point par descente et par sortie pourra être gagné. Le point sera accordé uniquement sur les descentes vraiment difficiles : 2/3 des personnes présentes n’y arrivent pas, sans mettre pied à terre. Si la personne tombe dans la descente, elle ne pourra pas gagner son point. Le CoDir doit statuer sur la validité du point une fois que tout le groupe aura franchi la difficulté.</p>

    <h2>Article 7 : Chute</h2>
    <p>Si lors d’une sortie, une personne fait une chute, celle-ci est enregistrée. Elle ne compte pas dans le total des points du Classement Interne.</p>

    <h2>Article 8 : Extras</h2>
    <p>Apporter quelque chose après ou pendant la sortie, rendre service de manière spontanée à l’ensemble du groupe, ce genre d’action rapporte un point. Les membres qui se portent bénévoles pour les différentes actions organisées par l’association gagnent un point extra par activité.</p>

    <h2>Article 9 : Total</h2>
    <p>La colonne Total permet de classer les membres en faisant la somme des points, sans compter les chutes. La présence compte double pour favoriser les membres réguliers.</p>

    <h2>Article 10 : Tri</h2>
    <p>Le tri, à position égale, se fait en fonction de la présence puis des points mauvais temps et enfin des points tranche kilomètre.</p>

    <h2>Article 11 : Participation</h2>
    <p>La participation compte le nombre de sortie faite en fonction du nombre total de sortie organisée, en valeur relative. Elle est calculée sur le total des sorties pour tous les membres même s’ils sont arrivés en cours de saison.</p>

    <h2>Article 12 : Réclamations</h2>
    <p>Toutes réclamations concernant des points oubliés ou en trop doivent être faites avant la sortie suivante. Seul le CoDir statuera.</p>

    <h2>Article 13 : Comptage des points</h2>
    <p>Une personne désignée par le groupe doit noter les points lors des sorties. En cas de perte ou en cas d’erreur, c’est le CoDir, dans son intégralité, qui se prononcera sur ce qui compte ou non.</p>
  </v-container>
</template>

<script>
export default {}
</script>
