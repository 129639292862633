<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" temporary app>
      <v-list dense>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>Choix saison</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list-item-group v-model="saison" color="primary" mandatory>
                <v-list-item
                  link
                  v-for="(saison, index) in saisonList"
                  :key="index"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="saison.nom"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-list-item to="/membres">Membres</v-list-item>
        <v-list-item to="/rules">Règlement</v-list-item>
        <v-list-item to="/stats">Statistiques</v-list-item>
        <v-list-item v-permission="'editer membres'" to="/admin/saisons">Admin Saisons</v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="primary" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title class="hidden-sm-and-down"
        >Classement XBC</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <div v-if="isLogged">
        <v-btn key="classement-header-link" text rounded to="/"
          >Classement</v-btn
        >
        <v-btn key="sorties-header-link" text rounded to="/sorties"
          >Sorties</v-btn
        >
        <v-menu bottom left>
          <template v-slot:activator="{ on }">
            <v-btn dark icon v-on="on">
              <v-icon>mdi-account-box</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item>
              <v-list-item-title>
                <v-btn @click="goToMember" text rounded>
                  <v-icon>mdi-account</v-icon>{{ getLoggedInUserPrenom }}</v-btn
                >
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-btn text rounded @click="logout" class="my-2"
                >Déconnexion</v-btn
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div v-else>
        <v-btn key="`${link.label}-header-link`" text rounded to="/login"
          >Connexion</v-btn
        >
      </div>
    </v-app-bar>
    <v-main>
      <router-view></router-view>
    </v-main>

    <v-footer color="primary lighten-1" padless>
      <v-row justify="center" no-gutters>
        <v-btn
          v-for="link in links"
          :key="`${link.label}-footer-link`"
          color="white"
          text
          rounded
          class="my-2"
          :to="link.url"
          >{{ link.label }}</v-btn
        >
        <v-btn
          v-if="isLogged"
          color="white"
          text
          rounded
          class="my-2"
          @click="logout"
          >Déconnexion</v-btn
        >
        <v-col class="primary lighten-2 py-4 text-center white--text" cols="12">
          {{ new Date().getFullYear() }} — <strong>Xtreme Bike Club</strong> by
          Benji
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import router from '@/router'
export default {
  name: 'App',
  // components: {
  //   Login
  // },

  data: () => ({
    drawer: false,
    links: [
      {
        label: 'Classement',
        url: '/'
      },
      {
        label: 'Sorties',
        url: '/sorties'
      }
    ],
    saison: 0,
    saisonList: []
  }),
  computed: {
    ...mapActions(['changeSaison', 'fetchSaisons']),
    ...mapState(['saisons']),
    ...mapGetters(['isLogged', 'getLoggedInUserPrenom', 'getLoggedInUserID'])
  },
  created: function() {
    this.$store.dispatch('fetchSaisons').then(() => {
      this.saisonList = this.saisons
      this.loadingSaisons = false
    })
  },
  methods: {
    logout() {
      this.$store.dispatch('logout').then(() => {
        router.push({ name: 'login' }).then(() => {
          location.reload
        })
      })
    },
    goToMember() {
      router.push({
        name: 'membre-show',
        params: { id: this.getLoggedInUserID }
      })
    }
  },
  watch: {
    saison: function() {
      this.$store.dispatch('changeSaison', this.saison)
    }
  }
}
</script>
