<template>
  <div>
    <v-card class="mb-5">
      <v-card-title>Statistiques saisonnières</v-card-title>
      <v-card-text>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="stats"
          dense
          hide-default-footer
          disable-pagination
          :mobile-breakpoint="0"
        ></v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import EventService from '@/services/EventService.js'
export default {
  props: {
    membreId: {
      required: true
    }
  },
  data: () => ({
    stats: [],
    loading: true,
    headers: [
      { text: 'Saison', value: 'nom' },
      { text: 'Participations', value: 'participations' },
      { text: 'Meteo', value: 'pointMauvaisTemps' },
      { text: 'Rando', value: 'pointRando' },
      { text: 'Pt Km', value: 'point_km' },
      { text: 'Grimp', value: 'grimpette' },
      { text: 'Desc', value: 'descente' },
      { text: 'Malus', value: 'malus' },
      { text: 'Chute', value: 'chute' },
      { text: 'Distance', value: 'km' },
      { text: 'Déniv', value: 'deniv' }
    ]
  }),
  mounted() {
    EventService.fetchMembreStats(this.membreId).then(reponse => {
      this.loading = false
      this.stats = reponse.data
    })
  }
}
</script>

<style lang="scss" scoped></style>
